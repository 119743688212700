import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import React, { useEffect, useState } from 'react';

import clock from '../../images/samsung/clock.svg'
import email from '../../images/samsung/email.svg'
import phone from '../../images/samsung/phone.svg'
import line from '../../images/samsung/line.svg'
import whatsapp from '../../images/samsung/whatsapp.svg'
import { GTM_TAGS, OTHER_REQ_OPTIONS, PARTNER_COUNTRY } from '../../utils/constants';
import { gtmEventTrack } from '../../utils/utilities';
import Button from '../common/Button';
import SamsungModalPopup from '../modal/SamsungModal';

import './samsungcontent.modules.scss';

export default function ContentComp() {
	const intl = useIntl() || 'en';
	const [languageParam, setLanguageParam] = useState('EN');
	const [showModal, setShowModal] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [showPopupAndModal, setShowPopupAndModal] = useState(false);

	useEffect(() => {
		let language = intl.locale;
		if (language === 'zh') {
			language = 'hk';
		}
		setLanguageParam(language.toUpperCase())
		setShowModal(OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY].SHOW_POPUP)
		setShowPopup(OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY].SHOW_POPUP)
		setShowPopupAndModal(OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY].SHOW_POPUP_AND_MODAL)
	}, [])

	const visitPortal = (e) => {
		e.preventDefault();
		window.open(`${process.env.GATSBY_SERVICE_PORTAL_URL}&language=${languageParam}`, "_blank");

		gtmEventTrack({
			...GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY][
				'SERVICE_PORTAL'
			].CLICK,
			action: "Click",
		});
	}

	const closeModal = () => {
		setShowPopup(false)
	};

	const whatsappLink = `https://wa.me/62${intl.formatMessage({ id: "whatsapp" }).replace(/\s/g, '')}`;
	const targetBlank = '_blank';
	const relNoReferrer = 'noreferrer';

	return (
		<div className='contentWrapper'>
			<SamsungModalPopup
				hideCloseBtn={false}
				show={showPopup}
				size="md"
				heading={intl.formatMessage({ id: "popup_heading" })}
				onClose={() => closeModal()}
			>
				<div style={{ fontWeight: '300' }}>
					<div>
						<div className={process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MO ? 'text-justify':'text-left'}>
							<FormattedHTMLMessage id="popup_subheading" /></div>
						{process.env.GATSBY_COUNTRY == PARTNER_COUNTRY.MY && <br/>}
						{process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.MY && process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.HK && process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.MO &&
							<div className='text-left mb-3 ml-2'><FormattedHTMLMessage id="popup_subheading1" /></div>}
						{process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.HK && process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.MO &&
							<div className={process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.MY && 'ml-3 ' + 'text-left'}>{intl.formatMessage({ id: "popup_subheading2" })}</div>}
						{process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.HK && process.env.GATSBY_COUNTRY != PARTNER_COUNTRY.MO &&
							<ul className='center-align'>
								<li><FormattedHTMLMessage id="popup_list_item1" /></li>
								<li><FormattedHTMLMessage id="popup_list_item2" /></li>
								<li><FormattedHTMLMessage id="popup_list_item3" /></li>
							</ul>
						}
					</div>
					<div className='btn'>
						<span></span>
						<a
							type="button"
							className="static-btn  btn-space samsung-btn"
							onClick={() => closeModal()}
						>
							{intl.formatMessage({ id: "popup_button" })}
						</a>
					</div>
				</div>
			</SamsungModalPopup>
			<div className='d-flex flex-column'>
				<h1 className='heading1' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "heading" }) }}></h1>
				{intl.formatMessage({ id: "login" }) !== 'login' && (
					<h5 className='heading5'>{intl.formatMessage({ id: "login" })}</h5>
				)}
				{(showPopupAndModal || !showModal) && (
					<p className='portal_cta_parent'>
						<a data-track-btn="sPortal" onClick={visitPortal} rel="noreferrer" target="_blank">
							<Button trackBtn="sPortal" className='visitPortalButton visit-portal' onClick={visitPortal} style={{ background: '#FFFFFF' }}>{intl.formatMessage({ id: "visitCustomerPortal" })}</Button>
						</a>
					</p>
				)}
				<div className={`mb-4 otherWays`}>
					{intl.formatMessage({ id: "otheways" })}
				</div>
			</div>
			<div className='contentWrapper__left'>
				{intl.formatMessage({ id: "phone" }) !== 'phone' && (<p className='d-flex contentWrapper__left__para'>
					<span><img src={phone} width='20' height='20' alt='phone' /></span>
					<a href={`tel:${intl.formatMessage({ id: "phone" })}`}><span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "phone" }) }}></span></a>
				</p>
				)}

				{intl.formatMessage({ id: "lineLabel" }) !== 'lineLabel' && (<p className='contentWrapper__left__para' style={{ minWidth: '300px' }}>
					<span><img src={line} width='20' height='20' alt='line' /></span>
					<a href={intl.formatMessage({ id: "lineLink" })}><span>{intl.formatMessage({ id: "lineLabel" })}</span></a>
				</p>
				)}

				{intl.formatMessage({ id: "email" }) && (<p className='contentWrapper__left__para' style={{ minWidth: '300px' }}>
					<span><img src={email} width='20' height='20' alt='email' /></span>
					<a href={`mailto:${intl.formatMessage({ id: "email" })}`}><span>{intl.formatMessage({ id: "email" })}</span></a>
				</p>
				)}

				{intl.formatMessage({ id: "contactNumber" }) !== 'contactNumber' && (<p className='d-flex contentWrapper__left__para'>
					<span><img src={phone} width='20' height='20' alt='contactNumber' /></span>
					<a href={`tel:${intl.formatMessage({ id: "contactNumber" })}`}><span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contactNumber" }) }}></span></a>
				</p>
				)}

				{intl.formatMessage({ id: "whatsapp" }) !== 'whatsapp' && (<p className='contentWrapper__left__para' style={{ minWidth: '300px' }}>
					<span><img src={whatsapp} width='20' height='20' alt='whatapp' /></span>
					<a href={whatsappLink} target={targetBlank} rel={relNoReferrer}><span>{intl.formatMessage({ id: "whatsapp" })}</span></a>
				</p>
				)}
			
				{intl.formatMessage({ id: "time" }) && (<p className={`d-flex contentWrapper__left__max_width `}>
					<span><img src={clock} width='20' height='20' alt='time' /></span>
					<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "time" }) }}></span>
				</p>
				)}
				{intl.formatMessage({ id: "days" }) !== 'days' && (
					<p className='d-flex mb-3 ml-3 '>
						<span className='mr4'></span>
						<span className='mr-0 pl-1 days'>{intl.formatMessage({ id: "days" })}</span>
					</p>
				)}
				{intl.formatMessage({ id: "weekendsTimings" }) !== 'weekendsTimings' && (
					<p className={`d-flex contentWrapper__left__max_width `}>
						<span className='extra_timing'></span>
						<span>{intl.formatMessage({ id: "weekendsTimings" })}</span>
					</p>
				)}
				{intl.formatMessage({ id: "weekendsDays" }) !== 'weekendsDays' && (
					<p className='d-flex mb-1 ml-3 '>
						<span></span>
						<span className='mr-0 pl-1 '>{intl.formatMessage({ id: "weekendsDays" })}</span>
					</p>
				)}
				{intl.formatMessage({ id: "holidays" }) !== 'holidays' && (
					<p className='d-flex ml-3 '>
						<span className='mr4'></span>
						<span className='mr-0 pl-1 days'>{intl.formatMessage({ id: "holidays" })}</span>
					</p>
				)}
			</div>
			{process.env.GATSBY_COUNTRY == PARTNER_COUNTRY.MY && <div className='d-flex flex-column '>
				<p className='landingAnnouncementMessage' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "landingAnnouncementMessage" }) }}></p>
			</div>}
		</div>
	)

}
