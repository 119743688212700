import React from "react";
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout";
import SEO from "../components/seo";
import CookiePolicy from '../components/common/CookiePolicy/index';
import { SITE_METADATA, PARTNER_DETAILS } from "./../utils/constants";

function IndexPage() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
          lang={intl.locale}
          keywords={SITE_METADATA[process.env.GATSBY_PARTNER].keywords}
          title={SITE_METADATA[process.env.GATSBY_PARTNER].title}
          bodyClass={`${process.env.GATSBY_PARTNER}-${process.env.GATSBY_COUNTRY} ${process.env.GATSBY_PARTNER}-${process.env.GATSBY_COUNTRY}-${intl.locale}`}
      />
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.6.0.min.js"
          integrity="sha384-vtXRMe3mGCbOeY7l30aIg8H9p3GdeSe4IFlP6G8JMa7o7lXvnz3GFKzPxzJdPfGK"
          crossOrigin="anonymous"
          type="text/javascript"
          async
        />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={
            process.env.GATSBY_COOKIE_SCRIPT_ID
          }
        />
        <script type="text/javascript">
          function OptanonWrapper()
          { }
        </script>
        {(process.env.GATSBY_PARTNER === PARTNER_DETAILS.EIR || process.env.GATSBY_PARTNER === PARTNER_DETAILS.DREI || process.env.GATSBY_PARTNER === PARTNER_DETAILS.MTEL || process.env.GATSBY_PARTNER === PARTNER_DETAILS.EPIC) && <CookiePolicy />}
      </Helmet>
      
    </Layout>
  );
}

export default IndexPage;
