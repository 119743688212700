import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import banner from '../../images/samsung/banner.jpg';
import BannerComp from '../SamsungBanner';
import ContentComp from '../SamsungContent';
import { PARTNER_COUNTRY } from "../../utils/constants";
import '../SamsungBanner/samsungbanner.modules.scss';

export default function ContainerComp() {
    return (
        process.env.GATSBY_PARTNER === "samsung"
         && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.TH  
            || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK
            || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.ID
            || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MO
            || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY
            || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.PH)
        ?
            <Row className="content-wrapper h-100">
                <Col md="6" className="banner-container">
                    <BannerComp />
                </Col>
                <Col md="6" style={{paddingLeft:0}}>
                    <ContentComp />
                </Col>
            </Row>
        :
            <Row className="content-wrapper h-100">
                <Col md="6">
                    <BannerComp />
                </Col>
                <Col md="6">
                    <ContentComp />
                </Col>
            </Row>
    )
}
